import _ from 'lodash'
import React from 'react'
import useStyles from './login-style'
import {
  ContentGroup,
  CouponPublicShow,
  Layout,
  Loading,
  SEO,
} from '../../ui'

function LoginView({
  children,
  // the page will be ready after required data is preloaded
  pageReady,
  contentGroup,
  paramsData,
  seoTitle,
}) {
  const {
    containerFluid,
    container,
    contentGroupContainer,
  } = useStyles()

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo title */}
      <SEO title={seoTitle} />
      {pageReady ? (
        <div className={containerFluid}>
          <div className={container}>
            {
              _.has(paramsData, 'couponId') && (
                <CouponPublicShow id={_.get(paramsData, 'couponId')} />
              )
            }
            {
              !_.isEmpty(contentGroup) && (
                <div className={contentGroupContainer}>
                  <ContentGroup
                    {...contentGroup}
                  />
                </div>
              )
            }
            {children}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Layout>
  )
}

export default LoginView
