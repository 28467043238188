import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[15, 20, 30]],
  },
  container: {
    maxWidth: '37rem',
    margin: '0 auto',
  },
  contentGroupContainer: {
    marginTop: '4rem',
    marginBottom: '4rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: [[60, 0]],
      width: '100%',
    },
    container: {
      maxWidth: '37rem',
      margin: [[0, 'auto']],
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export default useStyles
