/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import {
  ErrorMessage,
  Field,
  useFormikContext,
} from 'formik'
import { isSafari } from 'react-device-detect';
import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import combineClassNames from '../../../../helpers/combineClassNames'
import useStyles from './email-or-phone-input-style'
import arrowDownIcon from '../../../../assets/icons/icon_dropdown_arrow.svg'

const EmailOrPhoneInputView = (props) => {
  const { t } = useTranslation()
  const {
    setFieldValue,
    values,
  } = useFormikContext()
  const {
    countryCallingCodeOptions,
    acceptableInputTypes = [
      'email',
      'phone',
    ],
    onInputTypeChange,
  } = props
  const [phoneEmailValue, setPhoneEmailValue] = useState('')
  // const [fieldAutoComplete, setFieldAutoComplete] = useState('')
  const styles = useStyles({ isSafari })

  const label = useMemo(() => (
    _.size(acceptableInputTypes) > 1
      ? t('screens.login.form.phoneOrEmail', { context: 'label' })
      : t(`screens.login.form.${_.first(acceptableInputTypes)}`, { context: 'label' })
  ), [acceptableInputTypes])

  const currentInputType = useMemo(() => {
    if (!_.isEmpty(values.phone)) return 'phone'
    if (!_.isEmpty(values.email)) return 'email'
    if (_.size(acceptableInputTypes) === 1) return _.first(acceptableInputTypes)
    return null
  }, [values, acceptableInputTypes])

  const selectProps = {
    name: 'countryCallingCode',
    options: countryCallingCodeOptions,
    className: styles.select,
    classNamePrefix: styles.select,
    components: {
      DropdownIndicator: (componentProps) => (
        <components.DropdownIndicator {...componentProps}>
          <img src={arrowDownIcon} alt="" />
        </components.DropdownIndicator>
      ),
    },
  }

  const handleFieldChange = (e) => {
    const value = _.get(e, 'target.value', '')
    const type = /^(\+)?(?:[ 0-9()-]){1,24}$/.test(value) ? 'phone' : 'email'
    // setFieldAutoComplete(type === 'phone' ? 'tel-local' : 'email')
    setEmailOrPhoneValues({ value, type })
  }

  const setEmailOrPhoneValues = ({ value, type }) => {
    if (
      type === 'phone'
      && _.includes(acceptableInputTypes, 'phone')
    ) {
      if (!_.isEmpty(_.get(values, 'email'))) {
        setFieldValue('email', null)
      }
      let localPhoneNumber = _.replace(value, /[\s()-]/g, '')
      if (localPhoneNumber.length > 1 && localPhoneNumber.charAt(0) === '0') {
        localPhoneNumber = _.replace(localPhoneNumber, /^0/, '')
      }
      const countryCallingCode = _.get(values, 'countryCallingCode') || _.get(_.first(countryCallingCodeOptions), 'value')
      setPhoneEmailValue(localPhoneNumber)
      setFieldValue('phone', `${countryCallingCode}${localPhoneNumber}`)
      setFieldValue('countryCallingCode', countryCallingCode)
      setFieldValue('localPhoneNumber', localPhoneNumber)
    } else if (_.includes(acceptableInputTypes, 'email')) {
      if (!_.isEmpty(_.get(values, 'phone'))) {
        setFieldValue('phone', null)
      }
      setFieldValue('email', value)
      setPhoneEmailValue(value)
    }
  }

  useEffect(() => {
    if (
      _.isNil(currentInputType)
      || !_.isFunction(onInputTypeChange)
    ) return
    onInputTypeChange(currentInputType)
  }, [currentInputType])

  // Auto select first countryCallingCode
  useEffect(() => {
    if (
      !_.isEmpty(currentInputType)
      && _.isEmpty(_.get(values, 'countryCallingCode'))
    ) {
      setFieldValue(
        'countryCallingCode',
        _.get(_.first(countryCallingCodeOptions), 'value'),
      )
    }
  }, [currentInputType, values])

  return (
    <div
      className={styles.phoneOrEmail}
    >
      <label
        htmlFor="emailOrPhone"
        className={combineClassNames([styles.inputLabel, styles.inputLabelRequired])}
      >
        {label}
      </label>
      <div className={styles.phoneOrEmailInner}>
        <div
          className={combineClassNames([
            styles.codeSelect,
            _.isEqual(currentInputType, 'phone') && styles.codeSelectShow,
          ])}
        >
          <Select
            {...selectProps}
            value={_.find(countryCallingCodeOptions, { value: _.get(values, 'countryCallingCode') })}
            onChange={({ value }) => {
              setFieldValue('countryCallingCode', value)
              setFieldValue('phone', `${value}${_.get(values, 'localPhoneNumber', '')}`)
            }}
          />
        </div>
        <Field
          className={styles.phoneOrEmailTextInput}
          id="emailOrPhone"
          name="emailOrPhone"
          autoComplete={!_.isEmpty(values.phone) ? 'tel-local' : 'email'}
          required
          value={phoneEmailValue}
          onChange={handleFieldChange}
          onBlur={handleFieldChange}
        />
      </div>
      {
        currentInputType && (
          <ErrorMessage component="p" className={styles.errorMessage} name={currentInputType} />
        )
      }
    </div>
  )
}

export default EmailOrPhoneInputView
